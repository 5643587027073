'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
exports.toOutputScript = exports.fromOutputScript = exports.toBech32 = exports.toBase58Check = exports.fromBech32 = exports.fromBase58Check = void 0;
const networks = require('./networks');
const payments = require('./payments');
const bscript = require('./script');
const types_1 = require('./types');
const bech32_1 = require('bech32');
const bs58check = require('bs58check');
const FUTURE_SEGWIT_MAX_SIZE = 40;
const FUTURE_SEGWIT_MIN_SIZE = 2;
const FUTURE_SEGWIT_MAX_VERSION = 16;
const FUTURE_SEGWIT_MIN_VERSION = 2;
const FUTURE_SEGWIT_VERSION_DIFF = 0x50;
const FUTURE_SEGWIT_VERSION_WARNING = 'WARNING: Sending to a future segwit version address can lead to loss of funds. ' + 'End users MUST be warned carefully in the GUI and asked if they wish to proceed ' + 'with caution. Wallets should verify the segwit version from the output of fromBech32, ' + 'then decide when it is safe to use which version of segwit.';
function _toFutureSegwitAddress(output, network) {
  const data = output.slice(2);
  if (data.length < FUTURE_SEGWIT_MIN_SIZE || data.length > FUTURE_SEGWIT_MAX_SIZE) throw new TypeError('Invalid program length for segwit address');
  const version = output[0] - FUTURE_SEGWIT_VERSION_DIFF;
  if (version < FUTURE_SEGWIT_MIN_VERSION || version > FUTURE_SEGWIT_MAX_VERSION) throw new TypeError('Invalid version for segwit address');
  if (output[1] !== data.length) throw new TypeError('Invalid script for segwit address');
  console.warn(FUTURE_SEGWIT_VERSION_WARNING);
  return toBech32(data, version, network.bech32);
}
/**
 * decode address with base58 specification,  return address version and address hash if valid
 */
function fromBase58Check(address) {
  const payload = Buffer.from(bs58check.decode(address));
  // TODO: 4.0.0, move to "toOutputScript"
  if (payload.length < 21) throw new TypeError(address + ' is too short');
  if (payload.length > 21) throw new TypeError(address + ' is too long');
  const version = payload.readUInt8(0);
  const hash = payload.slice(1);
  return {
    version,
    hash
  };
}
exports.fromBase58Check = fromBase58Check;
/**
 * decode address with bech32 specification,  return address version、address prefix and address data if valid
 */
function fromBech32(address) {
  let result;
  let version;
  try {
    result = bech32_1.bech32.decode(address);
  } catch (e) {}
  if (result) {
    version = result.words[0];
    if (version !== 0) throw new TypeError(address + ' uses wrong encoding');
  } else {
    result = bech32_1.bech32m.decode(address);
    version = result.words[0];
    if (version === 0) throw new TypeError(address + ' uses wrong encoding');
  }
  const data = bech32_1.bech32.fromWords(result.words.slice(1));
  return {
    version,
    prefix: result.prefix,
    data: Buffer.from(data)
  };
}
exports.fromBech32 = fromBech32;
/**
 * encode address hash to base58 address with version
 */
function toBase58Check(hash, version) {
  (0, types_1.typeforce)((0, types_1.tuple)(types_1.Hash160bit, types_1.UInt8), arguments);
  const payload = Buffer.allocUnsafe(21);
  payload.writeUInt8(version, 0);
  hash.copy(payload, 1);
  return bs58check.encode(payload);
}
exports.toBase58Check = toBase58Check;
/**
 * encode address hash to bech32 address with version and prefix
 */
function toBech32(data, version, prefix) {
  const words = bech32_1.bech32.toWords(data);
  words.unshift(version);
  return version === 0 ? bech32_1.bech32.encode(prefix, words) : bech32_1.bech32m.encode(prefix, words);
}
exports.toBech32 = toBech32;
/**
 * decode address from output script with network, return address if matched
 */
function fromOutputScript(output, network) {
  // TODO: Network
  network = network || networks.bitcoin;
  try {
    return payments.p2pkh({
      output,
      network
    }).address;
  } catch (e) {}
  try {
    return payments.p2sh({
      output,
      network
    }).address;
  } catch (e) {}
  try {
    return payments.p2wpkh({
      output,
      network
    }).address;
  } catch (e) {}
  try {
    return payments.p2wsh({
      output,
      network
    }).address;
  } catch (e) {}
  try {
    return payments.p2tr({
      output,
      network
    }).address;
  } catch (e) {}
  try {
    return _toFutureSegwitAddress(output, network);
  } catch (e) {}
  throw new Error(bscript.toASM(output) + ' has no matching Address');
}
exports.fromOutputScript = fromOutputScript;
/**
 * encodes address to output script with network, return output script if address matched
 */
function toOutputScript(address, network) {
  network = network || networks.bitcoin;
  let decodeBase58;
  let decodeBech32;
  try {
    decodeBase58 = fromBase58Check(address);
  } catch (e) {}
  if (decodeBase58) {
    if (decodeBase58.version === network.pubKeyHash) return payments.p2pkh({
      hash: decodeBase58.hash
    }).output;
    if (decodeBase58.version === network.scriptHash) return payments.p2sh({
      hash: decodeBase58.hash
    }).output;
  } else {
    try {
      decodeBech32 = fromBech32(address);
    } catch (e) {}
    if (decodeBech32) {
      if (decodeBech32.prefix !== network.bech32) throw new Error(address + ' has an invalid prefix');
      if (decodeBech32.version === 0) {
        if (decodeBech32.data.length === 20) return payments.p2wpkh({
          hash: decodeBech32.data
        }).output;
        if (decodeBech32.data.length === 32) return payments.p2wsh({
          hash: decodeBech32.data
        }).output;
      } else if (decodeBech32.version === 1) {
        if (decodeBech32.data.length === 32) return payments.p2tr({
          pubkey: decodeBech32.data
        }).output;
      } else if (decodeBech32.version >= FUTURE_SEGWIT_MIN_VERSION && decodeBech32.version <= FUTURE_SEGWIT_MAX_VERSION && decodeBech32.data.length >= FUTURE_SEGWIT_MIN_SIZE && decodeBech32.data.length <= FUTURE_SEGWIT_MAX_SIZE) {
        console.warn(FUTURE_SEGWIT_VERSION_WARNING);
        return bscript.compile([decodeBech32.version + FUTURE_SEGWIT_VERSION_DIFF, decodeBech32.data]);
      }
    }
  }
  throw new Error(address + ' has no matching Script');
}
exports.toOutputScript = toOutputScript;