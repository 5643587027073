"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sha1 = void 0;
const _md_js_1 = require("./_md.js");
const utils_js_1 = require("./utils.js");
// SHA1 (RFC 3174) was cryptographically broken. It's still used. Don't use it for a new protocol.
// Initial state
const SHA1_IV = /* @__PURE__ */new Uint32Array([0x67452301, 0xefcdab89, 0x98badcfe, 0x10325476, 0xc3d2e1f0]);
// Temporary buffer, not used to store anything between runs
// Named this way because it matches specification.
const SHA1_W = /* @__PURE__ */new Uint32Array(80);
class SHA1 extends _md_js_1.HashMD {
  constructor() {
    super(64, 20, 8, false);
    this.A = SHA1_IV[0] | 0;
    this.B = SHA1_IV[1] | 0;
    this.C = SHA1_IV[2] | 0;
    this.D = SHA1_IV[3] | 0;
    this.E = SHA1_IV[4] | 0;
  }
  get() {
    const {
      A,
      B,
      C,
      D,
      E
    } = this;
    return [A, B, C, D, E];
  }
  set(A, B, C, D, E) {
    this.A = A | 0;
    this.B = B | 0;
    this.C = C | 0;
    this.D = D | 0;
    this.E = E | 0;
  }
  process(view, offset) {
    for (let i = 0; i < 16; i++, offset += 4) SHA1_W[i] = view.getUint32(offset, false);
    for (let i = 16; i < 80; i++) SHA1_W[i] = (0, utils_js_1.rotl)(SHA1_W[i - 3] ^ SHA1_W[i - 8] ^ SHA1_W[i - 14] ^ SHA1_W[i - 16], 1);
    // Compression function main loop, 80 rounds
    let {
      A,
      B,
      C,
      D,
      E
    } = this;
    for (let i = 0; i < 80; i++) {
      let F, K;
      if (i < 20) {
        F = (0, _md_js_1.Chi)(B, C, D);
        K = 0x5a827999;
      } else if (i < 40) {
        F = B ^ C ^ D;
        K = 0x6ed9eba1;
      } else if (i < 60) {
        F = (0, _md_js_1.Maj)(B, C, D);
        K = 0x8f1bbcdc;
      } else {
        F = B ^ C ^ D;
        K = 0xca62c1d6;
      }
      const T = (0, utils_js_1.rotl)(A, 5) + F + E + K + SHA1_W[i] | 0;
      E = D;
      D = C;
      C = (0, utils_js_1.rotl)(B, 30);
      B = A;
      A = T;
    }
    // Add the compressed chunk to the current hash value
    A = A + this.A | 0;
    B = B + this.B | 0;
    C = C + this.C | 0;
    D = D + this.D | 0;
    E = E + this.E | 0;
    this.set(A, B, C, D, E);
  }
  roundClean() {
    SHA1_W.fill(0);
  }
  destroy() {
    this.set(0, 0, 0, 0, 0);
    this.buffer.fill(0);
  }
}
exports.sha1 = (0, utils_js_1.wrapConstructor)(() => new SHA1());
